import React from 'react'
import { mixins, theme } from '../../../styles'

export interface IFilterButton {
  bgColor?: string
  bgColorHighlight?: string
  isHighlight?: boolean
  onClick?: () => void
  textColor?: string
  textColorHighlight?: string
  title: React.ReactNode
  href?: string
  dataTestId?: string
}

export const FilterButton = React.memo(function FilterButton({
  bgColor,
  bgColorHighlight,
  isHighlight,
  onClick,
  href,
  textColor,
  textColorHighlight,
  title,
  dataTestId,
}: IFilterButton): JSX.Element {
  const RenderComponent = href ? 'a' : 'button'

  return (
    <>
      <RenderComponent
        className={[
          'FilterButton flex items-center justify-center',
          isHighlight ? 'FilterButton_Highlight' : '',
        ].join(' ')}
        type={href ? undefined : 'button'}
        onClick={onClick}
        href={href ? href : undefined}
        data-testid={dataTestId}
      >
        {title}
      </RenderComponent>

      <style jsx>{`
        .FilterButton {
          background: ${bgColor ? bgColor : theme.cssVars.transparent};
          padding: ${(bgColor && bgColor !== 'transparent') ||
          (isHighlight && bgColorHighlight !== 'transparent')
            ? '2px 12px 0'
            : 0};
        }
        .FilterButton_Highlight {
          background: ${bgColorHighlight ? bgColorHighlight : theme.cssVars.focusBlue};
        }
      `}</style>
      <style jsx>{`
        .FilterButton {
          color: ${textColorHighlight && isHighlight
            ? textColorHighlight
            : textColor
            ? textColor
            : theme.cssVars.darkBlue};
        }
      `}</style>
      <style jsx>{`
        .FilterButton {
          cursor: pointer;
          height: 32px;
          border-width: 0;
          border-radius: 24px;
          
          font-style: normal;
          font-weight: 900;
          font-size: 13px;
          text-transform: uppercase;
          white-space: nowrap;
        }

        @media ${mixins.mediaQuery.tablet} {
          .FilterButton {
            font-size: 14px;
          }
        }
      `}</style>
    </>
  )
})
