import type { ITopic } from '../../atoms/Topic'
import type { ISponsorBanner } from '../SponsorBanner'
import { useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useAmp } from 'next/amp'
import { Link } from '../../atoms/Link'
import { TF1InfoLogo } from '../../atoms/Icon/TF1InfoLogo'
import { Icon } from '../../atoms/Icon'
import { FilterButton } from '../../atoms/FilterButton'
import { NotificationsButton } from '../../atoms/NotificationsButton'
import { UserButton } from '../../atoms/UserButton'
import { MenuBurgerButton } from '../../atoms/MenuBurgerButton'
import { stringToId } from '../../../helpers/stringToId'
import { useUser } from '../../../hook/useUser'
import { useTMSHitForHeader } from '../../../hook/useTMSClickHit'
import { DownloadBannerContext } from '../../../contexts/DownloadBanner'
import { MENUSECONDARY_ID } from '../../../constants/components'
import { FORYOU_URL } from '../../../constants/home'
import { mixins, theme } from '../../../styles'

export interface IHeaderHome {
  topic?: ITopic
  sponsor?: ISponsorBanner
  elementList: {
    title: string
    bgColor: string
    link: string
    icon?: string
  }[]
  link: string
  wordings?: {
    login?: string
  }
}

const WIDTH_HOME_NAVBAR = 110
const WIDTH_HOME_NAVBAR_MOBILE = 100

export function HeaderHome({ elementList, link, wordings }: IHeaderHome): JSX.Element {
  const { asPath } = useRouter()
  const { isLoggedIn, loading } = useUser()
  const { isDisplayed: isDownloadBannerDisplayed } = useContext(DownloadBannerContext)
  const { headerHit } = useTMSHitForHeader()
  const hash = asPath?.split('#')[1]
  const pathname = asPath?.split('?')[0].replace(`#${hash}`, '')
  const isHome = pathname === '/'
  const [isHeaderSmall, setIsHeaderSmall] = useState(!isHome)
  const isAmp = useAmp()
  const getClassNameNavBarSelect = () => {
    const index = (elementList.findIndex((element) => element.link === link) || 0) + 1
    const selectedIndexClass = `Select_${index}`
    return `Header__Container__NavBar__Select ${selectedIndexClass}`
  }

  const getIntersectionObserver = (): IntersectionObserver =>
    new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        const [entry] = entries

        setIsHeaderSmall(entry.intersectionRatio === 0)
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: [0, 1.0],
      },
    )

  useEffect(() => {
    // If we are on the home page, we observe the body header as it is always there
    if (isHome) {
      const bodyHeaderComponent = document.querySelector("[data-module='body-header']")
      if (bodyHeaderComponent) {
        const intersectionObserver = getIntersectionObserver()
        intersectionObserver.observe(bodyHeaderComponent)

        return () => {
          intersectionObserver.disconnect()
        }
      }
    }
  }, [pathname, isLoggedIn, loading])

  return (
    <>
      <header
        role="banner"
        aria-label="En-tête principale"
        className={[
          'HeaderHome',
          isHeaderSmall ? 'HeaderHome_Sticky' : '',
          !isHome ? 'HeaderHome_ForYou' : '',
        ]
          .join(' ')
          .trim()}
      >
        <div className="HeaderHome__Logo">
          <Link url="/" onClick={() => headerHit('logo')} title="Tf1 Info - Retour à l'accueil">
            <TF1InfoLogo />
          </Link>
        </div>
        <nav
          role="navigation"
          aria-label="Menu Secondaire"
          className="HeaderHome__NavBar"
          id={MENUSECONDARY_ID}
          tabIndex={-1}
        >
          {elementList?.length ? (
            <ul className="HeaderHome__NavBar__Container">
              {elementList.map((button) => {
                const isAllNewsButton = button.link === '/'
                const isForYouButton = button.link === `/${FORYOU_URL}/`
                return (
                  <li key={stringToId(`Filter-${button.title}`)}>
                    <FilterButton
                      href={button.link}
                      isHighlight={button.link === pathname}
                      textColor={theme.cssVars.white}
                      textColorHighlight={theme.cssVars.white}
                      bgColorHighlight={button.bgColor}
                      bgColor={button.bgColor}
                      title={
                        <>
                          {button.icon ? (
                            <Icon
                              name={button.icon}
                              primaryColor={theme.cssVars.white}
                              secondaryColor={theme.cssVars.white}
                              size="18px"
                            />
                          ) : null}
                          <span className="HeaderHome__NavBar__Title">{button.title}</span>
                        </>
                      }
                      dataTestId={
                        isAllNewsButton
                          ? 'lnk-toute-l-info'
                          : isForYouButton
                          ? 'lnk-pour-vous'
                          : null
                      }
                      onClick={() => headerHit(button.title)}
                    />
                  </li>
                )
              })}
              <div className={getClassNameNavBarSelect()} />
            </ul>
          ) : null}
        </nav>
        <div className="HeaderHome__Actions">
          <UserButton unloggedWording={wordings?.login} />
          {!isAmp && <NotificationsButton />}
          <MenuBurgerButton />
        </div>
      </header>

      <style jsx>{`
        @media ${mixins.mediaQuery.tablet} {
          .HeaderHome {
            top: ${isDownloadBannerDisplayed && !isHeaderSmall ? '88px' : '0'};
          }

          .HeaderHome_Sticky {
            animation: ${isHome ? 'appearFromTop 600ms ease-in' : 'none'};
          }
        }
      `}</style>
      <style jsx>{`
        @keyframes fade {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }

        @keyframes appearFromTop {
          from {
            transform: translateY(-100%);
          }
          to {
            transform: translateY(0);
          }
        }

        .HeaderHome__Placeholder {
          display: none;
        }

        .HeaderHome {
          position: sticky;
          height: ${`${theme.layout.mobile.header.height}px`};
          top: 0;
          z-index: 20;
          display: flex;
          flex-direction: column;
          background-color: ${theme.cssVars.deepBlue};
        }

        .HeaderHome_ForYou {
          border-color: ${theme.cssVars.darkBlue};
          background-color: ${theme.cssVars.darkestBlue};
        }

        .HeaderHome__NavBar__Title {
          height: 16px;
        }

        .HeaderHome__Logo {
          position: absolute;
          top: 10px;
          left: 0;
          transform: translateX(calc(50vw - 50%));
          pointer-events: auto;

          display: flex;
          align-items: center;
          justify-content: center;
        }

        .HeaderHome__Logo :global(svg) {
          width: 165px;
          height: 55px;
        }

        .HeaderHome__NavBar {
          position: relative;
          width: 100%;
        }

        .HeaderHome__NavBar__Container {
          position: absolute;
          top: 76px;
          left: 0;
          right: 0;
          transform: translateX(calc(50vw - 50%));
          margin: 0 auto;
          display: grid;
          grid-auto-flow: column;
          grid-template-columns: ${`${WIDTH_HOME_NAVBAR_MOBILE}px ${WIDTH_HOME_NAVBAR_MOBILE}px ${WIDTH_HOME_NAVBAR_MOBILE}px`};
          gap: 18px;
          justify-content: center;
          align-items: center;
          padding: 0;
          width: 100%;
          list-style: none;
          overflow-x: clip;
        }

        .HeaderHome__NavBar__Container :global(> *) {
          pointer-events: auto;
        }

        .Header__Container__NavBar__Select {
          position: absolute;
          bottom: calc(-${theme.layout.desktop.headerHomeNav.height}px + 9px);
          left: 0;
          right: 0;
          margin: 0 auto 0;
          transform: translateY(-50%);
          width: 140px;
          height: 5px;
          background-color: ${theme.cssVars.yellowGolden};
          -webkit-transition: all 500ms linear, bottom 0ms linear, background-color 500ms,
            width 500ms;
          transition: all 500ms linear, bottom 0ms linear, background-color 500ms, width 500ms;
        }

        .Header__Container__NavBar__Select.Select_3 {
          width: ${`calc(${WIDTH_HOME_NAVBAR}px + 10px)`};
          transform: translatex(100%);
          background-color: ${theme.cssVars.yellowGolden};
        }

        .Header__Container__NavBar__Select.Select_2 {
          transform: translatex(0);
          width: ${WIDTH_HOME_NAVBAR}px;
          background-color: ${theme.cssVars.focusBlue};
        }

        .Header__Container__NavBar__Select.Select_1 {
          width: ${`calc(${WIDTH_HOME_NAVBAR}px + 10px)`};
          transform: translatex(-100%);
          background-color: ${theme.cssVars.red};
        }

        .HeaderHome__NavBar__Container
          :global(*:nth-child(1):hover ~ .Header__Container__NavBar__Select) {
          transform: translatex(-100%);

          width: ${`calc(${WIDTH_HOME_NAVBAR}px + 10px)`};
          background-color: ${theme.cssVars.red};
        }

        .HeaderHome__NavBar__Container
          :global(*:nth-child(2):hover ~ .Header__Container__NavBar__Select) {
          transform: translatex(0%);

          width: ${`${WIDTH_HOME_NAVBAR}px`};
          background-color: ${theme.cssVars.focusBlue};
        }

        .HeaderHome__NavBar__Container
          :global(*:nth-child(3):hover ~ .Header__Container__NavBar__Select) {
          transform: translatex(100%);

          width: ${`calc(${WIDTH_HOME_NAVBAR}px + 10px)`};
          background-color: ${theme.cssVars.yellowGolden};
        }

        .HeaderHome__NavBar__Container :global(.Icon) {
          margin-right: 8px;
        }

        .HeaderHome {
          border-bottom: 5px solid ${theme.cssVars.darkBlue};
        }

        .HeaderHome__Actions {
          pointer-events: none;
          position: absolute;
          top: 20px;
          left: 0;
          width: 100vw;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          gap: 20px;
          padding: ${`0 max(20px, calc(50vw - ${
            theme.layout.maxWidth / 2 + theme.block.marginLR
          }px + 32px))`};
        }

        .HeaderHome__Actions :global(> *) {
          pointer-events: auto;
        }

        .HeaderHome__Actions :global(.UserButton) {
          margin-right: auto;
        }

        @media ${!isHome ? mixins.mediaQuery.desktop : mixins.mediaQuery.tablet} {
          .HeaderHome {
            position: absolute;
            pointer-events: none;
            height: ${`${theme.layout.desktop.bigHeader.height}px`};
            width: 100%;
            left: 0;
            background: linear-gradient(rgba(0, 10, 104, 0.4), transparent);
            border-bottom: 0px;
          }

          .HeaderHome {
            border-bottom: none;
          }

          .HeaderHome__NavBar__Container {
            top: 90px;
          }

          .HeaderHome_Sticky .HeaderHome__Logo {
            top: 12px;
          }
        }

        @media ${mixins.mediaQuery.tablet} {
          .HeaderHome.HeaderHome_Sticky {
            border-bottom: 5px solid ${theme.cssVars.darkBlue};
          }

          .HeaderHome_Sticky {
            position: fixed;
            pointer-events: auto;
            height: ${`${theme.layout.desktop.headerHome.height}px`};
            top: 0;
            background-color: var(--color-deepBlue);
          }

          .HeaderHome_ForYou.HeaderHome_Sticky {
            position: sticky;
            background-color: ${theme.cssVars.darkestBlue};
          }

          .HeaderHome_Sticky .HeaderHome__Logo {
            left: 30px;
            top: 12px;
            transform: translateX(0);
          }

          .HeaderHome__Actions :global(.UserButton) {
            margin-right: initial;
          }

          .HeaderHome_Sticky .HeaderHome__NavBar__Container {
            top: 22px;
          }
          .Header__Container__NavBar__Select {
            bottom: -15px;
          }

          .HeaderHome_Sticky .Header__Container__NavBar__Select {
            bottom: calc(-${theme.layout.desktop.headerHomeNav.height}px + 8px);
          }
        }

        @media ${mixins.mediaQuery.onlyTablet} {
          .HeaderHome_Sticky .HeaderHome__NavBar__Container {
            left: 38px;
          }
        }

        @media ${mixins.mediaQuery.tablet} {
          .HeaderHome__NavBar__Container {
            width: 50%;
            grid-template-columns: ${`${WIDTH_HOME_NAVBAR}px ${WIDTH_HOME_NAVBAR}px ${WIDTH_HOME_NAVBAR}px`};
            margin: 0;
          }

          .Header__Container__NavBar__Select.Select_3 {
            width: ${`calc(${WIDTH_HOME_NAVBAR}px + 20px)`};
          }

          .Header__Container__NavBar__Select.Select_2 {
            width:  ${`calc(${WIDTH_HOME_NAVBAR}px + 10px)`};
          }

          .Header__Container__NavBar__Select.Select_1 {
            width: ${`calc(${WIDTH_HOME_NAVBAR}px + 20px)`};
          }

          .HeaderHome__NavBar__Container
            :global(*:nth-child(1):hover ~ .Header__Container__NavBar__Select) {
            width: ${`calc(${WIDTH_HOME_NAVBAR}px + 20px)`};

          }

          .HeaderHome__NavBar__Container
            :global(*:nth-child(2):hover ~ .Header__Container__NavBar__Select) {
            width: ${`calc(${WIDTH_HOME_NAVBAR}px + 10px)`};

          }

          .HeaderHome__NavBar__Container
            :global(*:nth-child(3):hover ~ .Header__Container__NavBar__Select) {
            width: ${`calc(${WIDTH_HOME_NAVBAR}px + 20px)`};

          }

          :not(.HeaderHome_Sticky) .HeaderHome__Logo {
            top: -9px;
          }

          .HeaderHome__Logo :global(svg) {
            width: 180px;
            height: 55px;
          }

          :not(.HeaderHome_Sticky) .HeaderHome__Logo :global(svg) {
            width: 331px;
            height: 103px;
          }
        }

        @media ${mixins.mediaQuery.desktop} {
          .HeaderHome__NavBar__Container {
            gap: 38px;
          }

           .Header__Container__NavBar__Select.Select_3 {
          width: ${`calc(${WIDTH_HOME_NAVBAR}px + 40px)`};
        }

        .Header__Container__NavBar__Select.Select_2 {
          width:  ${`calc(${WIDTH_HOME_NAVBAR}px + 20px)`};
        }

        .Header__Container__NavBar__Select.Select_1 {
          width: ${`calc(${WIDTH_HOME_NAVBAR}px + 40px)`};
        }

        .HeaderHome__NavBar__Container
          :global(*:nth-child(1):hover ~ .Header__Container__NavBar__Select) {
          width: ${`calc(${WIDTH_HOME_NAVBAR}px + 40px)`};

        }

        .HeaderHome__NavBar__Container
          :global(*:nth-child(2):hover ~ .Header__Container__NavBar__Select) {
          width: ${`calc(${WIDTH_HOME_NAVBAR}px + 20px)`};

        }

        .HeaderHome__NavBar__Container
          :global(*:nth-child(3):hover ~ .Header__Container__NavBar__Select) {
          width: ${`calc(${WIDTH_HOME_NAVBAR}px + 40px)`};

        }
        @media ${mixins.mediaQuery.maxWidth} {
          .HeaderHome_Sticky .HeaderHome__Logo {
            left: ${`max(calc(50vw - ${
              theme.layout.maxWidth / 2 + theme.block.marginLR
            }px), 32px)`};
          }
        }
      `}</style>
    </>
  )
}
